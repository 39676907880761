



































































































































































































































































































































































































































































































































































































import Component, { mixins } from 'vue-class-component';
import WidgetMixins from '../../../mixins/WidgetMixins';
import DialogMixins from '../../../mixins/DialogMixins';
import { Getter, namespace } from 'vuex-class';
import { IContact, IMessage, IPaginate } from '@/types/types';
import { loadView, loadWidget } from '@/utils/helpers';
import { Watch } from 'vue-property-decorator';
import { debounce } from 'lodash';

const contactModule = namespace('contact');
const groupModule = namespace('group');
@Component({
  name: 'Index',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
    AddContactDialog: loadView('pages/contacts/dialogs/AddContactDialog'),
    EditContactDialog: loadView('pages/contacts/dialogs/EditContactDialog'),
    DeleteDialog: loadWidget('widgets/DeleteDialog'),
    BulkUploadDialog: loadWidget('widgets/BulkUpload'),
    BulkExportDialog: loadWidget('widgets/BulkExport'),
    SendSMSDialog: loadView(`pages/contacts/dialogs/SendSMSDialog`),
    SendSMSToAllDialog: loadView(`pages/contacts/dialogs/SendSMSToAllDialog`),
    AddSMSSenderID: loadWidget(`widgets/AddSenderID`),
  },
  filters: {},
})
export default class Index extends mixins(WidgetMixins, DialogMixins) {
  @Getter('getCreditBalance') creditBalance!: number;
  @contactModule.Getter('getContactDetails') details!: IContact;
  @groupModule.Getter('getAllGroups') groups!: ReadonlyArray<IContact>;
  @Getter('getResetFormState') resetFormState!: boolean;
  @contactModule.Getter('getPagination') paginate!: Pick<
    IPaginate,
    'page' | 'total' | 'itemsPerPage'
  > & { limit: number };
  @contactModule.Getter('getAllContacts') contacts!: ReadonlyArray<IContact>;

  options: IPaginate = {
    itemsPerPage: 30,
    page: 1,
    total: 0,
  };
  assignGroups: number[] = [];
  selected: number[] = [];
  sort: string | null = null;
  action: string | null = null;

  headers: Array<{
    text: string;
    value: string;
    align?: string;
    sortable?: boolean;
  }> = [
    {
      text: '',
      value: 'avatar',
      sortable: false,
    },

    {
      text: 'Name',
      value: 'name',
    },
    {
      text: 'Phone Number',
      value: 'phone',
    },
    {
      text: 'Group Name',
      value: 'groupContactsId',
      sortable: false,
    },
    {
      text: 'Actions',
      align: 'center',
      value: 'actions',
      sortable: false,
    },
  ];

  search = '';

  searchText(query: string): void {
    if (query !== '') {
      this.$store.dispatch('contact/searchContacts', {
        query,
        page: 1,
        isPaginated: true,
        limit: 30,
        sort: this.sort,
      });
    } else {
      this.$store.dispatch('contact/list', {
        isPaginated: true,
        page: 1,
        limit: 30,
        sort: this.sort,
      });
    }
  }

  handleOnSearchTextChange = debounce(this.searchText, 1000);

  @Watch('search')
  onSearchTermChange(term: string): void {
    this.handleOnSearchTextChange(term);
  }

  @Watch('resetFormState')
  onFormStateChange(state: boolean): void {
    if (state) {
      this.assignGroups = [];
      this.action = null;
    }
  }

  @Watch('options')
  onOptionsChange(payload: IPaginate): void {
    if (
      payload.sortBy &&
      payload.sortBy.length > 0 &&
      payload.sortDesc &&
      payload.sortDesc.length > 0
    ) {
      this.sort = `${payload.sortBy[0]},${
        payload.sortDesc[0] ? 'DESC' : 'ASC'
      }`;
    } else this.sort = null;
    if (this.search !== '') {
      this.$store.dispatch('contact/searchContacts', {
        query: this.search,
        page: payload.page,
        isPaginated: true,
        limit: payload.itemsPerPage,
        sort: this.sort,
      });
      return;
    }
    this.$store.dispatch('contact/list', {
      page: payload.page,
      limit: payload.itemsPerPage,
      isPaginated: true,
      sort: this.sort,
    });
  }

  addContact(payload: IContact): void {
    this.$store.dispatch('contact/create', payload);
  }

  editContact(payload: IContact): void {
    this.$store.dispatch('contact/update', payload);
  }

  deleteContact(): void {
    if (this.selected.length > 0) {
      this.$store.dispatch(
        'contact/deleteAll',
        this.action === 'ALL'
          ? 'ALL'
          : (this.selected as any).map((contact: any) => contact.id).join(',')
      );
      this.selected = [];
      return;
    }
    this.$store.dispatch('contact/delete', this.details?.id);
  }

  openDeleteDialog(val: never): void {
    this.open('delete');
    if (val) this.action = val;
  }

  addToGroup(id: number): void {
    this.$store.dispatch('contact/assignToGroup', {
      id,
      groups: this.assignGroups,
    });
  }

  copyContact(phone: string): void {
    this.$copyText(phone).then(
      () => {
        this.$store.dispatch('snackBarMessage', `Copied`, { root: true });
        this.$store.dispatch('snackBarVisibility', true, { root: true });
      },
      () => {
        this.$store.dispatch('snackBarMessage', `Unable to copy contact`, {
          root: true,
        });
        this.$store.dispatch('snackBarVisibility', true, { root: true });
      }
    );
  }

  bulkUpload(file: File): void {
    let formData = new FormData();
    formData.append('file', file, file.name);
    this.$store.dispatch('contact/bulkUpload', formData);
  }

  sendSMS({ body: { to, type, message, from } }: { body: IMessage }): void {
    const payload = {
      body: {
        messages: to?.split(',')?.map((phone) => {
          return {
            to: phone,
            message,
            from,
            type,
          };
        }),
      },
    };
    this.$store.dispatch('sms/sendBulkSMS', payload);
  }

  sendSMSToAll(payload: { body: IMessage; type: string }): void {
    this.$store.dispatch('sms/sendQuickSMSToAllContacts', payload);
  }

  created(): void {
    this.$store.dispatch('contact/list', {
      page: 1,
      limit: 30,
      sort: this.sort,
    });
    this.$store.dispatch('group/rawList');
    this.$store.dispatch('checkCreditBalance');
    this.$store.dispatch('auth/me');
  }
}
